/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useRouter } from 'next/router';
import { getError } from '../utils/error';
import { Col, Container, Form, Input, Label, Row } from 'reactstrap';
import Link from 'next/link';
import StoreContext from '../utils/store/StoreContext';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function LoginScreen() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const router = useRouter();
  const { redirect } = router.query;
  const { store, userInfo, setUserInfo } = useContext(StoreContext);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (userInfo) {
      router.push('/');
    }
  }, []);

  const submitHandler = async ({ email, password }) => {
    try {
      const { data } = await axios.post('/api/users/login', {
        email,
        password,
        accountId: store.accountId,
      });
      setUserInfo(data);

      router.push(redirect || '/');
    } catch (e) {
      toast.error(t(getError(e)));
    }
  };
  return (
    <Layout showBreadCrumbs={true} title={t('Login')} store={store}>
      <section className="login-page section-b-space">
        <Container>
          <Row>
            <Col lg="6">
              <h3>{t('Login')}</h3>
              <div className="theme-card">
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(submitHandler)}
                >
                  <div className="form-group">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                      render={({ field }) => (
                        <>
                          <Label for="email">{t('Email')}</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder={t('Email')}
                            required="required"
                            {...field}
                          />
                          <small className="text-danger">
                            {errors.email && errors.email.message}
                          </small>
                        </>
                      )}
                    ></Controller>
                  </div>
                  <div className="form-group">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        minLength: 8,
                      }}
                      render={({ field }) => (
                        <>
                          <Label for="password">{t('Password')}</Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder={t('Enter your password')}
                            required="required"
                            {...field}
                          />
                          <small className="text-danger">
                            {errors.password && errors.password.message}
                          </small>
                        </>
                      )}
                    ></Controller>
                  </div>
                  <button type="submit" className="btn btn-solid">
                    {t('Login')}
                  </button>
                </Form>
              </div>
            </Col>
            <Col lg="6" className="right-login">
              <h3>{t('New Customer')}</h3>
              <div className="theme-card authentication-right">
                <h6 className="title-font">{t('Create an Account')}</h6>
                <p>
                  {t('Sign up for a free account at our store')}.{' '}
                  {t('Registration is quick and easy')}.{' '}
                  {t('It allows you to be able to order from our shop')}.{' '}
                  {t('To start shopping click register')}.
                </p>
                <Link href={`/register?redirect=${redirect}`}>
                  <a className="btn btn-solid">{t('Create an Account')}</a>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'status'])),
    },
  };
}
